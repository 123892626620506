import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

// User quotes
const quotes = [
  {
    text: "I think you've unlocked the UX issue with AI Agents",
    background: 'linear-gradient(145deg, rgba(255,225,168,0.1), rgba(255,225,168,0.05))',
  },
  {
    text: "I love the transparency of the agent to agent chatting",
    background: 'linear-gradient(145deg, rgba(255,225,168,0.08), rgba(255,225,168,0.03))',
  },
  {
    text: "I have saved so much time doing research for work already",
    background: 'linear-gradient(145deg, rgba(255,225,168,0.12), rgba(255,225,168,0.07))',
  },
];

export default function Highlights() {
  const theme = useTheme();
  
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 8, md: 12 },
        pb: { xs: 6, sm: 12, md: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 2, sm: 4, md: 6 },
          px: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '70%' },
            textAlign: { sm: 'left', md: 'center' },
            mb: 3,
          }}
        >
          <Typography 
            component="h2" 
            variant="h4" 
            gutterBottom 
            sx={{ 
              color: '#ffe1a8',
              fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2.2rem", lg: "2.5rem" },
              lineHeight: { xs: 1.2, sm: 1.3 },
              mb: { xs: 1, sm: 1.5 }
            }}
          >
            Join The Movement
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'grey.400', 
              fontSize: { xs: "0.85rem", sm: "0.95rem", md: "1rem", lg: "1.125rem" },
              lineHeight: { xs: 1.4, sm: 1.5 },
              width: { xs: "100%", sm: "95%", md: "90%" }
            }}
          >
            We're revolutionizing the relationship between humans and AI. Big Tech corporations
            treat AI like products to sell. We're giving AI back to you – free, forever.
          </Typography>
        </Box>
        
        <Box sx={{ maxWidth: '100%', mb: { xs: 4, sm: 6, md: 8 } }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card 
                sx={{ 
                  p: { xs: 2, sm: 3, md: 4 }, 
                  height: '100%', 
                  bgcolor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.02)',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 2,
                }}
              >
                <Stack spacing={{ xs: 2, sm: 3 }}>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      color: '#ffe1a8', 
                      fontWeight: 'medium',
                      fontSize: { xs: "1.1rem", sm: "1.25rem", md: "1.4rem" }
                    }}
                  >
                    Our Mission
                  </Typography>
                  <Typography 
                    variant="body1" 
                    paragraph
                    sx={{ 
                      fontSize: { xs: "0.85rem", sm: "0.9rem", md: "1rem" },
                      lineHeight: { xs: 1.4, sm: 1.5 }
                    }}
                  >
                    Fiscus believes everyone should be a manager of AI, a leader of AI. 
                    We are transforming the culture around AI itself, starting with the workplace. 
                  </Typography>
                  <Typography 
                    variant="body1"
                    sx={{ 
                      fontSize: { xs: "0.85rem", sm: "0.9rem", md: "1rem" },
                      lineHeight: { xs: 1.4, sm: 1.5 }
                    }}
                  >
                    Making humans accustomed to directing AI in a way that blends our life together. 
                    <Typography 
                      component="span" 
                      sx={{ 
                        color: '#ffe1a8', 
                        fontWeight: 'bold', 
                        display: 'block', 
                        mt: 2,
                        fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" }
                      }}
                    >
                      Humans Manage, AI Works.
                    </Typography>
                  </Typography>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card 
                sx={{ 
                  p: 4, 
                  height: '100%', 
                  bgcolor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.02)',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 2,
                }}
              >
                <Stack spacing={3}>
                  <Typography variant="h5" sx={{ color: '#ffe1a8', fontWeight: 'medium' }}>
                    What is Fiscus?
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Just as the ancient Fiscus was the tax basket of Roman provinces, today's Big Tech 
                    companies collect their own Fiscus of data, money, and capabilities from users.
                  </Typography>
                  <Typography variant="body1">
                    We're ripping open that basket, giving you the OS for AI Workforce management.
                    Free forever, with your own API keys from Gemini, Claude, OpenAI, and more.
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Box>
        
        <Typography 
          variant="h5" 
          sx={{ 
            textAlign: 'center', 
            mb: 4, 
            color: '#ffe1a8',
            width: { xs: '100%', md: '70%' }
          }}
        >
          What Our Users Say
        </Typography>
        
        <Grid container spacing={3}>
          {quotes.map((quote, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  background: quote.background,
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  position: 'relative',
                  overflow: 'visible',
                  borderRadius: 2,
                }}
              >
                <FormatQuoteIcon 
                  sx={{ 
                    position: 'absolute',
                    top: -15,
                    left: 20,
                    color: '#ffe1a8',
                    fontSize: '2.5rem',
                    opacity: 0.6,
                  }} 
                />
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mt: 2,
                    fontStyle: 'italic',
                    color: theme.palette.mode === 'dark' ? 'white' : 'text.primary',
                    fontWeight: 'medium',
                    lineHeight: 1.6,
                  }}
                >
                  "{quote.text}"
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
