import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { visuallyHidden } from "@mui/utils";
import { styled, useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "auto",
  aspectRatio: "16 / 9",
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  outline: "2px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.15)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 8px 4px hsla(220, 25%, 80%, 0.15)",
  backgroundImage: `url(${require("../assets/team-page4.png")})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  transition: "background-image 0.8s ease-in-out",
  padding: "4px",
  [theme.breakpoints.up("xs")]: {
    height: "34vh",
    minHeight: 200,
    maxHeight: 300,
    backgroundSize: "contain",
    maxWidth: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    height: "40vh",
    minHeight: 260,
    maxHeight: 380,
    margin: "0 auto",
    marginTop: theme.spacing(3),
    maxWidth: "80%",
  },
  [theme.breakpoints.up("md")]: {
    height: "45vh",
    minHeight: 340,
    maxHeight: 480,
    maxWidth: "70%",
    marginTop: theme.spacing(4),
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 10px 6px hsla(210, 100%, 25%, 0.15)",
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  const theme = useTheme();
  const heroImageRef = React.useRef<HTMLDivElement>(null);
  
  // Instead of tracking general sections, we'll track feature elements in the Features section
  React.useEffect(() => {
    // Define the feature IDs or selectors we want to observe
    const featureSelectors = [
      ".feature-ai-command-center",
      ".feature-seamless-integration",
      ".feature-ai-team-management",
      ".feature-workforce-direction"
    ];
    
    // Images for different features
    const featureImages: Record<string, string> = {
      "command-center": "../assets/HR-command-center.png",
      "integration": "../assets/tools-page.png",
      "team-management": "../assets/ai-teamwork.png",
      "workforce-direction": "../assets/schedule-work.png",
      "default": "../assets/team-page4.png"
    };
    
    // Set default image
    if (heroImageRef.current) {
      try {
        const defaultImage = require("../assets/team-page4.png");
        heroImageRef.current.style.backgroundImage = `url(${defaultImage})`;
        heroImageRef.current.style.backgroundSize = "contain";
        heroImageRef.current.style.backgroundPosition = "center center";
      } catch (error) {
        console.warn("Failed to load default image", error);
      }
    }
    
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6 // Higher threshold to ensure element is substantially visible
    };
    
    const featureObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && heroImageRef.current) {
          // Extract feature type from class name
          const element = entry.target as HTMLElement;
          let imageKey = "default";
          
          if (element.classList.contains("feature-ai-command-center")) {
            imageKey = "command-center";
          } else if (element.classList.contains("feature-seamless-integration")) {
            imageKey = "integration";
          } else if (element.classList.contains("feature-ai-team-management")) {
            imageKey = "team-management";
          } else if (element.classList.contains("feature-workforce-direction")) {
            imageKey = "workforce-direction";
          }
          
          // Update image based on visible feature
          try {
            const image = require(featureImages[imageKey]);
            heroImageRef.current.style.backgroundImage = `url(${image})`;
            heroImageRef.current.style.backgroundSize = "contain";
            heroImageRef.current.style.backgroundPosition = "center center";
          } catch (error) {
            console.warn(`Failed to load image for feature: ${imageKey}`, error);
          }
        }
      });
    }, observerOptions);
    
    // Observe each feature element
    featureSelectors.forEach(selector => {
      const elements = document.querySelectorAll(selector);
      elements.forEach(element => {
        featureObserver.observe(element);
      });
    });
    
    return () => {
      featureObserver.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: { xs: "80vh", sm: "82vh", md: "84vh" },
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          pt: { xs: 6, sm: 8, md: 10 },
          pb: { xs: 2, sm: 4, md: 5 },
          px: { xs: 1, sm: 2, md: 3 },
        }}
      >
        <Stack
          spacing={{ xs: 1.5, sm: 2, md: 2.5 }}
          useFlexGap
          sx={{ 
            alignItems: "center", 
            width: { xs: "100%", sm: "95%", md: "90%" },
            mx: "auto",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              fontSize: { xs: "1.3rem", sm: "1.6rem", md: "2rem", lg: "2.5rem" },
              whiteSpace: "nowrap",
              lineHeight: 1.2,
              color: theme.palette.mode === 'dark' 
                ? "#ffe1a8"
                : theme.palette.text.primary,
              fontWeight: 'bold',
              mb: { xs: 0.5, sm: 1 },
              width: "100%",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Humans Manage, AI Works</span>
  
            </Box>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { xs: "100%", sm: "90%", md: "80%" },
              fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.95rem", lg: "1.1rem" },
              lineHeight: { xs: 1.4, sm: 1.5 },
              mt: { xs: 0, sm: 0.5 },
            }}
          >
            Transform from individual contributor to AI workforce manager. With Fiscus, you'll lead teams of AI agents working together on your tasks, freeing you from the hands-on work. Leverage your own API keys for complete autonomy, break free from Big Tech limitations, and experience the power of being the executive in charge.
          </Typography>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' }, 
              gap: { xs: 1, sm: 2 },
              mt: { xs: 1, sm: 1.5 },
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                fontWeight: 'bold',
                px: { xs: 2, sm: 3 },
                py: { xs: 1, sm: 1.2 },
                bgcolor: "#ffe1a8",
                color: "text.primary",
                fontSize: { xs: '0.8rem', sm: '0.85rem', md: '0.9rem' },
                "&:hover": {
                  bgcolor: alpha("#ffe1a8", 0.8),
                },
              }}
              href="https://app.fiscusflows.com"
            >
              Join the Movement
            </Button>
            <Typography 
              variant="caption" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                fontWeight: 'bold',
                color: "#ffe1a8",
                fontSize: { xs: '0.65rem', sm: '0.7rem' },
              }}
            >
              OPEN BETA • 100% FREE
            </Typography>
          </Box>
        </Stack>
        <StyledBox id="image" ref={heroImageRef} />
      </Container>
    </Box>
  );
}
