import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTheme } from '@mui/material/styles';

const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: '56.25%', // 16:9 aspect ratio
  height: 0,
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#030712' // Midnight Blue for dark mode
    : theme.palette.background.paper,
  '&:hover .play-button': {
    transform: 'translate(-50%, -50%) scale(1.1)',
    backgroundColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 225, 168, 0.9)'
      : theme.palette.primary.main,
  },
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
}));

const PlayButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 80,
  height: 80,
  borderRadius: '50%',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 225, 168, 0.8)'
    : theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'transform 0.3s ease, background-color 0.3s ease',
  zIndex: 1,
}));

export default function VideoDemo() {
  const theme = useTheme();
  const [playing, setPlaying] = React.useState(false);
  
  const handlePlayClick = () => {
    setPlaying(true);
  };
  
  return (
    <Container id="video-demo" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ maxWidth: 'md', mx: 'auto', textAlign: 'center', mb: 8 }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: '#ffe1a8', mb: 2 }}
        >
          See Fiscus in Action
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
          Watch how Fiscus empowers you to become a true AI leader. Transform your workflow 
          by directing AI teams with intuitive commands and strategic oversight.
        </Typography>
      </Box>
      <VideoContainer>
        {!playing && (
          <PlayButton className="play-button" onClick={handlePlayClick}>
            <PlayArrowIcon sx={{ fontSize: 40, color: theme.palette.mode === 'dark' ? '#030712' : '#fff' }} />
          </PlayButton>
        )}
        <iframe
          src={`https://www.youtube.com/embed/i4m_1aOkJDE?si=GCaLdU6S6jqk8_Yb${playing ? '&autoplay=1' : ''}`}
          title="Fiscus: The AI Workforce Revolution"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          referrerPolicy="strict-origin-when-cross-origin"
        />
      </VideoContainer>
    </Container>
  );
} 