import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import DiscordIcon from '@mui/icons-material/Forum';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import CloudIcon from '@mui/icons-material/Cloud';
import SitemarkIcon from "./SitemarkIcon";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"Copyright © "}
      <Link color="text.secondary" href="https://fiscusflows.com/">
        Fiscus
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "40%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
            <SitemarkIcon />
            <Typography
              variant="subtitle1"
              sx={{ color: "text.secondary", mt: 1 }}
            >
              Build Your Digital Workforce in 5 Minutes or Less
            </Typography>

            {/* <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 600, mt: 2 }}
            >
              Join the newsletter
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
              Subscribe for weekly updates. No spams ever!
            </Typography>
            <InputLabel htmlFor="email-newsletter">Email</InputLabel>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="email-newsletter"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Enter your email address"
                placeholder="Your email address"
                slotProps={{
                  htmlInput: {
                    autoComplete: "off",
                    "aria-label": "Enter your email address",
                  },
                }}
                sx={{ width: "250px" }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ flexShrink: 0 }}
              >
                Subscribe
              </Button>
            </Stack> */}
          </Box>
        </Box>

        {/* Navigation Menu - Horizontal instead of stacked */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            justifyContent: "center",
            flexWrap: "wrap",
            mt: { xs: 4, sm: 0 },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Link
              color="text.secondary"
              variant="body2"
              onClick={() =>
                document
                  .getElementById("features")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
              sx={{ 
                px: 2, 
                py: 1, 
                display: "inline-block",
                borderRadius: 1,
                transition: "all 0.2s",
                "&:hover": {
                  bgcolor: "rgba(255, 225, 168, 0.1)",
                }
              }}
            >
              Features
            </Link>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Link
              color="text.secondary"
              variant="body2"
              onClick={() =>
                document
                  .getElementById("highlights")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
              sx={{ 
                px: 2, 
                py: 1, 
                display: "inline-block",
                borderRadius: 1,
                transition: "all 0.2s",
                "&:hover": {
                  bgcolor: "rgba(255, 225, 168, 0.1)",
                }
              }}
            >
              Highlights
            </Link>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Link
              color="text.secondary"
              variant="body2"
              onClick={() =>
                document
                  .getElementById("faq")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
              sx={{ 
                px: 2, 
                py: 1, 
                display: "inline-block",
                borderRadius: 1,
                transition: "all 0.2s",
                "&:hover": {
                  bgcolor: "rgba(255, 225, 168, 0.1)",
                }
              }}
            >
              FAQs
            </Link>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Link
              color="text.primary"
              variant="body2"
              href="https://app.fiscusflows.com"
              sx={{ 
                px: 2, 
                py: 1, 
                display: "inline-block",
                borderRadius: 1,
                fontWeight: "bold",
                transition: "all 0.2s",
                color: "#ffe1a8",
                "&:hover": {
                  bgcolor: "rgba(255, 225, 168, 0.1)",
                }
              }}
            >
              Join the Movement
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="/privacy-policy">
            Privacy Policy
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="/terms-of-service">
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://github.com/fiscus-flows"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://discord.gg/3DqXett8"
            aria-label="Discord"
            sx={{ alignSelf: "center" }}
          >
            <DiscordIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.youtube.com/@fiscusflows"
            aria-label="YouTube"
            sx={{ alignSelf: "center" }}
          >
            <YouTubeIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.instagram.com/fiscusflows"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.tiktok.com/@fiscusflows"
            aria-label="TikTok"
            sx={{ alignSelf: "center" }}
          >
            <MusicNoteIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://bsky.app/profile/official-fiscus.bsky.social"
            aria-label="Bluesky"
            sx={{ alignSelf: "center" }}
          >
            <CloudIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/fiscus-flows/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
