import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const theme = useTheme();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(
        isExpanded
          ? [...expanded, panel]
          : expanded.filter((item) => item !== panel),
      );
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 8, md: 12 },
        pb: { xs: 6, sm: 12, md: 16 },
        px: { xs: 2, sm: 3, md: 4 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: '#ffe1a8',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2.2rem", lg: "2.5rem" },
          lineHeight: { xs: 1.2, sm: 1.3 },
          mb: { xs: 1, sm: 2 },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          disableGutters
          elevation={0}
          sx={(theme) => ({
            bgcolor: 'background.paper',
            '&:before': { display: 'none' },
            '&.Mui-expanded': {
              borderLeft: `2px solid ${theme.palette.primary.main}`,
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 225, 168, 0.03)' 
                : 'rgba(255, 225, 168, 0.05)',
            },
            transition: 'all 0.2s ease',
          })}
          expanded={expanded.includes('panel1')}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography 
              component="span" 
              variant="subtitle2"
              sx={{
                fontSize: { xs: "0.85rem", sm: "0.95rem", md: "1rem" },
                fontWeight: "medium",
              }}
            >
              Is Fiscus really 100% free? What's the catch?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '70%' },
                color: 'rgba(255, 255, 255, 0.7)',
                ...(theme.palette.mode === 'light' && {
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
                fontSize: { xs: "0.75rem", sm: "0.85rem", md: "0.9rem" },
                lineHeight: { xs: 1.4, sm: 1.5 },
              }}
            >
              Yes, Fiscus is completely free. There's no catch. We believe everyone should have access to AI management capabilities. You only pay for your own API usage to providers like OpenAI, Anthropic, or Google (many of which offer free tiers). We're democratizing access to AI workforce technology that would otherwise be locked behind expensive enterprise plans.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={(theme) => ({
            bgcolor: 'background.paper',
            '&:before': { display: 'none' },
            '&.Mui-expanded': {
              borderLeft: `2px solid ${theme.palette.primary.main}`,
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 225, 168, 0.03)' 
                : 'rgba(255, 225, 168, 0.05)',
            },
            transition: 'all 0.2s ease',
          })}
          expanded={expanded.includes('panel2')}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="span" variant="subtitle2">
              What does "Humans Manage, AI Works" actually mean?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '70%' },
                color: 'rgba(255, 255, 255, 0.7)',
                ...(theme.palette.mode === 'light' && {
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
              }}
            >
              It means we're changing the relationship between humans and AI. Instead of treating AI as a tool you have to learn, we position AI as workers that you manage. You give instructions in natural language, just like you would to a human team member, and our AI agents execute tasks collaboratively. This human-centered approach puts you in control as the manager of your AI workforce.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={(theme) => ({
            bgcolor: 'background.paper',
            '&:before': { display: 'none' },
            '&.Mui-expanded': {
              borderLeft: `2px solid ${theme.palette.primary.main}`,
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 225, 168, 0.03)' 
                : 'rgba(255, 225, 168, 0.05)',
            },
            transition: 'all 0.2s ease',
          })}
          expanded={expanded.includes('panel3')}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="span" variant="subtitle2">
              How do I set up my own AI team with Fiscus?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '70%' },
                color: 'rgba(255, 255, 255, 0.7)',
                ...(theme.palette.mode === 'light' && {
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
              }}
            >
              It's simple: sign up for Fiscus, connect your API key from providers like OpenAI, Anthropic (Claude), or Google (Gemini), and start giving tasks to your AI team. No coding or complex setup required. You can use plain language to direct your team, just like managing human employees, but with the speed and scalability of AI.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={(theme) => ({
            bgcolor: 'background.paper',
            '&:before': { display: 'none' },
            '&.Mui-expanded': {
              borderLeft: `2px solid ${theme.palette.primary.main}`,
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 225, 168, 0.03)' 
                : 'rgba(255, 225, 168, 0.05)',
            },
            transition: 'all 0.2s ease',
          })}
          expanded={expanded.includes('panel4')}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="span" variant="subtitle2">
              What kinds of tools can my AI team use?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '70%' },
                color: 'rgba(255, 255, 255, 0.7)',
                ...(theme.palette.mode === 'light' && {
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
              }}
            >
              Fiscus gives your AI workers access to over 5,000+ tools, allowing them to accomplish almost any digital task. They can search the web, process data, interact with other services, analyze documents, generate content, and much more. This extensive toolset lets your AI team handle complex workflows that would typically require multiple specialized applications or human intervention.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={(theme) => ({
            bgcolor: 'background.paper',
            '&:before': { display: 'none' },
            '&.Mui-expanded': {
              borderLeft: `2px solid ${theme.palette.primary.main}`,
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 225, 168, 0.03)' 
                : 'rgba(255, 225, 168, 0.05)',
            },
            transition: 'all 0.2s ease',
          })}
          expanded={expanded.includes('panel5')}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography component="span" variant="subtitle2">
              Why did Fiscus decide to make everything free?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '70%' },
                color: 'rgba(255, 255, 255, 0.7)',
                ...(theme.palette.mode === 'light' && {
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
              }}
            >
              We believe AI workforce management shouldn't be locked behind paywalls or controlled by Big Tech. Just as the ancient Roman "Fiscus" was a basket of tax collections from provinces, today's tech giants collect their "Fiscus" from users in the form of data, money, and capabilities. We're ripping that bag open, offering our platform for free to democratize access and give people agency over their own AI workers.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={(theme) => ({
            bgcolor: 'background.paper',
            '&:before': { display: 'none' },
            '&.Mui-expanded': {
              borderLeft: `2px solid ${theme.palette.primary.main}`,
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 225, 168, 0.03)' 
                : 'rgba(255, 225, 168, 0.05)',
            },
            transition: 'all 0.2s ease',
          })}
          expanded={expanded.includes('panel6')}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography component="span" variant="subtitle2">
              What makes Fiscus different from other AI platforms?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '70%' },
                color: 'rgba(255, 255, 255, 0.7)',
                ...(theme.palette.mode === 'light' && {
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
              }}
            >
              Fiscus is unique in three key ways: First, we're completely free, using your own API keys. Second, we focus on multi-agent collaboration, allowing AI workers to function as a team rather than individual tools. Third, our mission goes beyond technology—we're creating a movement to transform how humans and AI interact, with humans as managers in control of their AI workforce rather than the other way around.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={(theme) => ({
            bgcolor: 'background.paper',
            '&:before': { display: 'none' },
            '&.Mui-expanded': {
              borderLeft: `2px solid ${theme.palette.primary.main}`,
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 225, 168, 0.03)' 
                : 'rgba(255, 225, 168, 0.05)',
            },
            transition: 'all 0.2s ease',
          })}
          expanded={expanded.includes('panel7')}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7d-content"
            id="panel7d-header"
          >
            <Typography component="span" variant="subtitle2">
              What does "Open Beta" mean for users?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '70%' },
                color: 'rgba(255, 255, 255, 0.7)',
                ...(theme.palette.mode === 'light' && {
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
              }}
            >
              Being in Open Beta means that while all core features are functional, we're still actively developing and enhancing the platform. You'll get early access to cutting-edge AI management capabilities, with new features rolling out regularly. We welcome feedback and will be rapidly iterating based on user experience. Some advanced features like audio and team sharing are coming soon, but the full power of AI team management is available now.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
