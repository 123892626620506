import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import { styled, useTheme } from "@mui/material/styles";

import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import TeamImage1 from "../assets/team-create-1.png";
import TeamImage2 from "../assets/team-create-2.png";
import TeamImage3 from "../assets/team-create-3.png";
import TeamImage4 from "../assets/team-create-4.png";
import HRCommandCenter from "../assets/HR-command-center.png";
import ToolsPageImage from "../assets/tools-page.png";
import AITeamImage from "../assets/ai-teamwork.png";
import ScheduleWorkImage from "../assets/schedule-work.png";

const items = [
  {
    icon: <DashboardCustomizeRoundedIcon />,
    title: "AI Command Center",
    description: "Take control of your AI workforce through an intuitive command center. Direct multiple AI agents with simple prompts and oversee their progress like a true AI manager.",
    imageLight: HRCommandCenter,
    imageDark: HRCommandCenter,
  },
  {
    icon: <SyncRoundedIcon />,
    title: "Seamless Integration",
    description: "Bridge your AI workforce with your existing tools. As an AI leader, orchestrate workflows across platforms while maintaining your current business infrastructure.",
    imageLight: ToolsPageImage,
    imageDark: ToolsPageImage,
  },
  {
    icon: <GroupRoundedIcon />,
    title: "AI Team Management",
    description: "Lead multiple AI agents working in harmony. Define roles, delegate tasks, and oversee AI collaboration while maintaining human strategic oversight.",
    imageLight: AITeamImage,
    imageDark: AITeamImage,
  },
  {
    icon: <CalendarMonthRoundedIcon />,
    title: "24/7 Workforce Direction",
    description: "Guide your AI workforce to handle tasks around the clock. Schedule, automate, and manage AI operations while maintaining control over the strategic direction.",
    imageLight: ScheduleWorkImage,
    imageDark: ScheduleWorkImage,
  },
];

interface ChipProps {
  selected?: boolean;
}

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<ChipProps>(({ theme, selected }) => ({
  background: selected ? '#030712' : 'transparent', // Midnight Blue when selected
  color: selected ? '#FFE1A8' : theme.palette.text.primary, // Sand color for text when selected
  borderColor: '#FFE1A8', // Sand color border
  '&:hover': {
    background: 'rgba(3, 7, 18, 0.8)', // Slightly transparent Midnight Blue
  },
  '& .MuiChip-label': {
    color: selected ? '#FFE1A8' : theme.palette.text.primary,
  },
}));

interface MobileLayoutProps {
  selectedItemIndex: number;
  handleItemClick: (index: number) => void;
  selectedFeature: (typeof items)[0];
}

export function MobileLayout({
  selectedItemIndex,
  handleItemClick,
  selectedFeature,
}: MobileLayoutProps) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: { xs: 1.5, sm: 2 },
      }}
    >
      <Box 
        sx={{ 
          display: "flex", 
          gap: { xs: 1, sm: 1.5 }, 
          overflow: "auto",
          pb: 1,
        }}
      >
        {items.map(({ title }, index) => {          
          return (
            <Chip
              size="medium"
              key={index}
              label={title}
              onClick={() => handleItemClick(index)}
              selected={selectedItemIndex === index}
              sx={{ 
                minWidth: 'fit-content',
                fontSize: { xs: '0.7rem', sm: '0.8rem' },
                height: { xs: '28px', sm: '32px' },
              }}
            />
          );
        })}
      </Box>
      <Card variant="outlined">
        <Box
          sx={(theme) => ({
            width: "100%",
            height: { xs: "280px", sm: "350px", md: "400px" },
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
            transition: 'all 0.3s ease-in-out',
            ...theme.applyStyles("dark", {
              backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
            }),
          })}
        />
        <Box sx={{ px: { xs: 1.5, sm: 2 }, pb: { xs: 1.5, sm: 2 }, position: 'relative' }}>
          <Typography
            gutterBottom
            sx={{ 
              color: "#ffe1a8", 
              fontWeight: "medium",
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
              mt: { xs: 0.5, sm: 1 },
            }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              color: "text.secondary", 
              mb: { xs: 1, sm: 1.5 },
              fontSize: { xs: '0.75rem', sm: '0.8rem', md: '0.9rem' },
              lineHeight: { xs: 1.4, sm: 1.5 },
            }}
          >
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

// Add animation effect after theme imports
const fadeTransition = {
  opacity: 1,
  transition: 'opacity 0.3s ease-in-out',
};

export default function Features() {
  const theme = useTheme();
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [isAnimating, setIsAnimating] = React.useState(false);
  // Track if we're in auto-cycle mode or manual mode
  const [isAutoCycleEnabled, setIsAutoCycleEnabled] = React.useState(true);
  // Interval time for auto-cycling (in ms)
  const cycleDuration = 5000; 
  // Reference to timeout for cleanup
  const cycleTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  // Track user interaction time
  const lastInteractionRef = React.useRef<number>(Date.now());
  // Time to wait before resuming auto-cycle after user interaction (in ms)
  const resumeAutoCycleDelay = 15000;

  // Function to handle the transition to the next feature
  const transitionToFeature = (index: number) => {
    if (index === selectedItemIndex || isAnimating) return;
    
    setIsAnimating(true);
    // Fade out transition
    setTimeout(() => {
      setSelectedItemIndex(index);
      // Fade in transition
      setTimeout(() => {
        setIsAnimating(false);
      }, 150);
    }, 150);
  };

  // Handle click on a feature
  const handleItemClick = (index: number) => {
    // Update last interaction time
    lastInteractionRef.current = Date.now();
    
    // Clear any existing timeout
    if (cycleTimeoutRef.current) {
      clearTimeout(cycleTimeoutRef.current);
      cycleTimeoutRef.current = null;
    }
    
    // Disable auto-cycle when user manually selects a feature
    setIsAutoCycleEnabled(false);
    
    // Transition to the selected feature
    transitionToFeature(index);
    
    // Set up a timer to resume auto-cycle after delay
    cycleTimeoutRef.current = setTimeout(() => {
      setIsAutoCycleEnabled(true);
    }, resumeAutoCycleDelay);
  };

  // Auto-cycle effect
  React.useEffect(() => {
    if (!isAutoCycleEnabled || isAnimating) return;
    
    // Set up the interval for cycling through features
    const nextIndex = (selectedItemIndex + 1) % items.length;
    
    cycleTimeoutRef.current = setTimeout(() => {
      transitionToFeature(nextIndex);
    }, cycleDuration);
    
    // Cleanup function
    return () => {
      if (cycleTimeoutRef.current) {
        clearTimeout(cycleTimeoutRef.current);
        cycleTimeoutRef.current = null;
      }
    };
  }, [selectedItemIndex, isAnimating, isAutoCycleEnabled]);
  
  // Cleanup on unmount
  React.useEffect(() => {
    return () => {
      if (cycleTimeoutRef.current) {
        clearTimeout(cycleTimeoutRef.current);
      }
    };
  }, []);

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ 
      py: { xs: 6, sm: 12, md: 16 },
      px: { xs: 2, sm: 3, md: 4 }
    }}>
      <Box sx={{ width: { sm: "100%", md: "60%" } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ 
            color: "#ffe1a8",
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2.2rem", lg: "2.5rem" },
            lineHeight: { xs: 1.2, sm: 1.3 },
            mb: { xs: 1, sm: 1.5 }
          }}
        >
          Lead Your AI Workforce
        </Typography>
        <Typography
          variant="body1"
          sx={{ 
            color: "text.secondary", 
            mb: { xs: 2, sm: 4 },
            fontSize: { xs: "0.85rem", sm: "0.95rem", md: "1rem", lg: "1.1rem" },
            lineHeight: { xs: 1.4, sm: 1.5 },
            width: { xs: "100%", sm: "95%", md: "90%" }
          }}
        >
          Fiscus transforms you from an AI user into an AI manager. Take control of your workflow by directing 
          AI agents that handle repetitive tasks, integrate across platforms, and operate around the clock—all 
          under your strategic guidance.
        </Typography>
      </Box>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          gap: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "30%" },
            flexShrink: 0,
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 2,
            }}
          >
            {items.map(({ icon, title, description }, index) => {
              return (
                <Box
                  key={index}
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  className={
                    index === 0 ? "feature-ai-command-center" :
                    index === 1 ? "feature-seamless-integration" :
                    index === 2 ? "feature-ai-team-management" :
                    "feature-workforce-direction"
                  }
                  sx={[
                    (theme) => ({
                      p: 2,
                      height: "100%",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }),
                    selectedItemIndex === index && {
                      backgroundColor: "action.selected",
                    },
                  ]}
                >
                  <Box
                    sx={[
                      {
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        gap: 1,
                        textAlign: "left",
                        textTransform: "none",
                        color: "text.secondary",
                      },
                      selectedItemIndex === index && {
                        color: "text.primary",
                      },
                    ]}
                  >
                    {icon}

                    <Typography variant="h6" sx={{ color: "#ffe1a8" }}>{title}</Typography>
                    <Typography variant="body2">{description}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: { xs: "100%", md: "70%" },
            height: "var(--items-image-height)",
            // Apply animation
            opacity: isAnimating ? 0.3 : 1,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: 'transparent',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative', // For progress indicator
            }}
          >
            <Box
              sx={(theme) => ({
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${items[selectedItemIndex].imageLight})`,
                transition: 'all 0.3s ease-in-out',
                ...theme.applyStyles("dark", {
                  backgroundImage: `url(${items[selectedItemIndex].imageDark})`,
                }),
              })}
            />
            
            {/* Progress indicator for auto-cycle */}
            {isAutoCycleEnabled && !isAnimating && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: 4,
                  bgcolor: 'primary.main',
                  width: '0%',
                  animation: isAutoCycleEnabled ? `progressAnimation ${cycleDuration}ms linear` : 'none',
                  '@keyframes progressAnimation': {
                    '0%': {
                      width: '0%',
                    },
                    '100%': {
                      width: '100%',
                    },
                  },
                }}
              />
            )}
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
