import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import LogoCollection from "./components/LogoCollection";
import Highlights from "./components/Highlights";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import AppTheme from "./theme/AppTheme";
import VideoDemo from "./components/VideoDemo";
import FadeInSection from './components/common/FadeInSection';

export default function MarketingPage(props: { disableCustomTheme?: boolean }) {
	return (
	  <AppTheme {...props}>
		<CssBaseline enableColorScheme />
  
		<AppAppBar />
		<Hero />
		<div
		  style={{
			margin: 0,
			padding: 0,
			width: "100%",
			height: "100%",
			boxSizing: "border-box",
			overflowX: "hidden", // Prevent horizontal scrolling
		  }}
		>
		  <FadeInSection>
			<LogoCollection />
		  </FadeInSection>
		  <section
			id="features"
			style={{
			  margin: 0,
			  padding: "0",
			  width: "100%",
			  boxSizing: "border-box",
			}}
		  >
			<FadeInSection>
			  <Features />
			</FadeInSection>
		  </section>
		  <Divider />
		  <section
			id="video-demo"
			style={{
			  margin: 0,
			  padding: "0",
			  width: '100%',
			  boxSizing: 'border-box',
			}}
		  >
			<FadeInSection>
			  <VideoDemo />
			</FadeInSection>
		  </section>
		  <Divider />
		  <section
			id="highlights"
			style={{
			  margin: 0,
			  padding: "0",
			  width: "100%",
			  boxSizing: "border-box",
			}}
		  >
			<FadeInSection>
			  <Highlights />
			</FadeInSection>
		  </section>
		  <Divider />
		  {/* <section
			id="pricing"
			style={{
			  margin: 0,
			  padding: 0,
			  width: "100%",
			  boxSizing: "border-box",
			}}
		  >
			<Pricing />
		  </section>
		  <Divider /> */}
		  <section
			id="faq"
			style={{
			  margin: 0,
			  padding: "0",
			  width: "100%",
			  boxSizing: "border-box",
			}}
		  >
			<FadeInSection>
			  <FAQ />
			</FadeInSection>
		  </section>
		  <Divider />
		  <Footer />
		</div>
	  </AppTheme>
	);
  }